<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>grade</md-icon>
          </div>
          <h4 class="title">{{ $t("orders.orders") }}</h4>
          <md-button v-if="hasPermission('Write', 'Orders', '')" @click="addManualOrderModal = true" class="md-icon-button md-success add-button">
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-20">
              <md-field md-clearable>
                <label>{{ $t("orders.keywords") }}</label>
                <md-input v-model="filter.keywords" @input="debounceApplyFilters"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field md-clearable>
                <label>{{ $t("orders.status") }}</label>
                <md-select v-model="filter.shippingStatusId" name="filter.shippingStatusId" id="filter.shippingStatusId" @md-selected="debounceApplyFilters">
                  <md-option :key="i.id" v-for="i in shippingStatusOptions" :value="i.id">{{ i.name ? getI18N("orders.statuses.shippingStatuses", i.name) : "" }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-15">
              <md-field md-clearable>
                <label>{{ $t("orders.sku") }}</label>
                <md-input v-model="filter.sku" @input="debounceApplyFilters"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-15">
              <md-field md-clearable>
                <label>{{ $t("orders.carrier") }}</label>
                <md-select v-model="filter.carrier" name="filter.carrier" id="filter.carrier" @md-selected="debounceApplyFilters">
                  <md-option :key="i.id" v-for="i in carriers" :value="i.name">{{ i.name }}</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-15">
              <md-field md-clearable>
                <label>{{ $t("orders.pickType") }}</label>
                <md-select multiple v-model="filter.pickTypes" name="filter.pickTypes" id="filter.pickTypes" @md-selected="debounceApplyFilters">
                  <md-option :key="i.id" v-for="i in pickTypes" :value="i.id">{{ i.name }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-15">
              <md-field :md-clearable="!isWarehouseAssigned">
                <label>{{ $t("wms.warehouse") }}</label>
                <md-select v-model="filter.warehouseId" name="warehouseId" id="warehouseId" :disabled="isWarehouseAssigned" @md-selected="debounceApplyFilters">
                  <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-20">
              <p class="date-title">{{ $t("orders.orderDateFrom") }}</p>
              <simple-date-time-picker
                :id="`orderDateFrom`"
                @datetimeChanged="(d) => dateFilterChanaged(d, 'orderDateFrom')"
                @clearDatetime="() => clearDatetime('orderDateFrom')"
              ></simple-date-time-picker>
            </div>
            <div class="md-layout-item md-size-20">
              <p class="date-title">{{ $t("orders.orderDateTo") }}</p>
              <simple-date-time-picker :id="`orderDateTo`" @datetimeChanged="(d) => dateFilterChanaged(d, 'orderDateTo')" @clearDatetime="() => clearDatetime('orderDateTo')"></simple-date-time-picker>
            </div>
            <div class="md-layout-item md-size-20">
              <p class="date-title">{{ $t("orders.shippedDateFrom") }}</p>
              <simple-date-time-picker
                :id="`shippedDateFrom`"
                @datetimeChanged="(d) => dateFilterChanaged(d, 'shippedDateFrom')"
                @clearDatetime="() => clearDatetime('shippedDateFrom')"
              ></simple-date-time-picker>
            </div>
            <div class="md-layout-item md-size-20">
              <p class="date-title">{{ $t("orders.shippedDateTo") }}</p>
              <simple-date-time-picker
                :id="`shippedDateTo`"
                @datetimeChanged="(d) => dateFilterChanaged(d, 'shippedDateTo')"
                @clearDatetime="() => clearDatetime('shippedDateTo')"
              ></simple-date-time-picker>
            </div>

            <div class="md-layout md-layout-item md-size-20">
              <md-checkbox v-model="enableBulky">{{ $t("products.bulky") }}</md-checkbox>
              <md-switch v-model="filterBulky" :disabled="!enableBulky" />
            </div>
          </div>
          <div class="mb-5" style="margin-top: 1rem"></div>
          <md-menu md-size="medium" :md-offset-x="127" :md-offset-y="-41">
            <md-button md-menu-trigger class="md-success">{{ $t("orders.run") }}</md-button>

            <md-menu-content>
              <md-menu-item v-if="hasPermission('Read', 'Orders', '')" @click="exportModal = true">
                {{ $t("orders.export") }}
              </md-menu-item>
              <md-menu-item v-if="hasPermission('Write', 'Orders', '')" @click="setStatusModal = true">
                {{ $t("orders.send") }}
              </md-menu-item>
              <md-menu-item v-if="hasPermission('Write', 'Orders', '')" @click="deleteModal = true">
                {{ $t("orders.delete") }}
              </md-menu-item>
            </md-menu-content>
          </md-menu>
          <vue-json-to-csv ref="csvGenerator" v-if="hasPermission('Read', 'Orders', '')" :json-data-generator="exportOrders">
            <span v-show="false" />
            <!-- override slot default with emptiness/ghost element -->
          </vue-json-to-csv>
          <div class="table-sticky">
            <v-server-table :columns="tableColumns" :options="tableOptions" ref="table" class="table-orders" id="table-orders">
              <template #h__actions>
                <div class="head-selected" style="width: 100px">
                  <input ref="checkAllInput" v-if="hasPermission('Write', 'Orders', '')" type="checkbox" :checked="hasCheckedAll" @click="handleCheckAll()" />
                  <br />
                  <span v-if="!hasCheckedAll">
                    {{
                      $tc("table.selected", totalItems, {
                        num: totalSelectedItems,
                        count: totalItems,
                      })
                    }}
                  </span>
                  <span v-else>{{ $tc("table.selectedAll", totalItems) }}</span>
                </div>
              </template>
              <template #h__status>
                <span>{{ $t("orders.status") }}</span>
                <br />
                <span>{{ $t("orders.pickType") }}</span>
              </template>
              <template #h__customer>
                <div class="head-customer">
                  <span v-if="hasPermission('LimitRead', 'Orders', 'CustomerInvoiceNumber')">{{ $t("orders.receiptNumber") }}</span>
                  <span v-if="hasPermission('LimitRead', 'Orders', 'CustomerIdNumber')"><br />{{ $t("orders.customerNumber") }}</span>
                  <span v-if="hasPermission('LimitRead', 'Orders', 'CustomerOrderId')"><br />{{ $t("orders.customerOrderId") }}</span>
                </div>
              </template>
              <template #h__shipping>
                <div class="head-shipping">
                  <span v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressCompany')">{{ $t("orders.company") }}<br /></span>
                  <span v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressName')">{{ $t("orders.name") }}<br /></span>
                  <span v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressStreet')">{{ $t("orders.streetAndNumber") }}<br /></span>
                  <span
                    v-if="
                      hasPermission('LimitRead', 'Orders', 'ShippingAddressCountry') ||
                      hasPermission('LimitRead', 'Orders', 'ShippingAddressZipcode') ||
                      hasPermission('LimitRead', 'Orders', 'ShippingAddressTown')
                    "
                    >{{ $t("orders.countryAndZipcode") }}</span
                  >
                </div>
              </template>
              <template #h__carrier>
                <span>{{ $t("orders.table.carrier") }}</span
                ><br />
                <span>{{ $t("orders.shippingService") }}</span
                ><br />
                <span>{{ $t("orders.shippingWeight") }}</span>
              </template>
              <template #h__orderDate>
                <div class="head-order-date">
                  <span v-if="hasPermission('LimitRead', 'Orders', 'OrderDate')">{{ $t("orders.table.orderDate") }}</span
                  ><br />
                  <span v-if="hasPermission('LimitRead', 'Orders', 'ShippedDate')">{{ $t("orders.table.shippedDate") }}</span>
                </div>
              </template>
              <template #actions="{ row }">
                <div class="md-table-cell-container cell-actions">
                  {{ row.index }} <input v-if="hasPermission('Write', 'Orders', '')" type="checkbox" :checked="row.selected" @click="handleSelectRow(row)" />
                  <md-button
                    class="md-icon-button"
                    style="z-index: 1"
                    v-if="(row.shippingStatusName === 'error' || row.shippingStatusName === 'unshipped') && hasPermission('Write', 'Orders', '')"
                    @click="showEditModal(row)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button class="md-icon-button" style="z-index: 1" v-if="hasPermission('Write', 'Orders', '')" @click="copy(row.id)">
                    <md-icon>content_copy</md-icon>
                  </md-button>
                </div>
              </template>
              <template #customer="{ row }">
                <div class="cell-customer">
                  <span v-if="hasPermission('LimitRead', 'Orders', 'CustomerInvoiceNumber')">
                    <strong>{{ row.customerInvoiceNumber }}</strong
                    ><br />
                  </span>
                  <span v-if="hasPermission('LimitRead', 'Orders', 'CustomerIdNumber')"
                    ><strong>{{ row.customerIdNumber }}</strong></span
                  ><br />
                  <span v-if="hasPermission('LimitRead', 'Orders', 'CustomerOrderId')"
                    ><strong>{{ row.customerOrderId }}</strong></span
                  >
                </div>
              </template>
              <template #shipping="{ row }">
                <div class="cell-shipping">
                  <div v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressCompany')" class="address-item">
                    <md-icon>business</md-icon> <strong v-if="row.shippingAddressCompany">{{ row.shippingAddressCompany }}</strong>
                  </div>
                  <div v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressName')" class="address-item"><md-icon>person</md-icon> {{ row.shippingAddressName }}</div>
                  <div v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressStreet')" class="address-item"><md-icon>place</md-icon> {{ row.shippingAddressStreet }}</div>
                  <span v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressCountry')" class="address-item">
                    <md-icon>map</md-icon> {{ row.shippingAddressCountry }} - <span v-if="hasPermission('LimitRead', 'Orders', 'shippingAddressZipcode')">{{ row.shippingAddressZipcode }} </span
                    ><span v-if="hasPermission('LimitRead', 'Orders', 'ShippingAddressTown')"> {{ row.shippingAddressTown }}</span>
                  </span>
                </div>
              </template>
              <template #sku="{ row }">
                <div class="cell-sku">
                  <ul v-if="row.orderSkus && hasPermission('LimitRead', 'Orders', 'OrderSkus')">
                    <li v-for="s in row.orderSkus" :key="s.id">
                      <strong>{{ s.shippingSkuAmount }} x {{ s.shippingSkuName }}</strong>
                      <span v-if="s.shippingSkuCarrier || s.shippingSkuLocation">
                        &nbsp; ({{ s.shippingSkuCarrier }}{{ s.shippingSkuCarrier ? ", " : "" }}{{ parseShippingSkuLocation(s.shippingSkuLocation, s.id) }})
                      </span>
                      <span v-if="s.orderSkuBulky === 'true'" class="material-symbols-outlined shipping-bulky">
                        package_2
                        <md-tooltip md-direction="top">Bulky</md-tooltip>
                      </span>
                    </li>
                  </ul>
                </div>
              </template>
              <template #status="{ row }">
                <div v-if="hasPermission('LimitRead', 'Orders', 'ShippingStatusId')" class="cell-status">
                  <md-chip :class="row.shippingStatusName">{{ row.shippingStatusName }}</md-chip>
                </div>
                <div v-if="hasPermission('LimitRead', 'Orders', 'pickType')" class="cell-status">
                  <md-chip :class="`order-pick-type-${row.pickType || 'na'}`"> {{ $t(`orders.pickTypes.${row.pickType || "NA"}`) }}</md-chip>
                </div>
              </template>
              <template #carrier="{ row }">
                <div v-if="hasPermission('LimitRead', 'Orders', 'OutboundCarriers')" class="cell-outbound-carriers">
                  {{ row.outboundCarriers }}
                </div>
                <div v-if="hasPermission('LimitRead', 'Orders', 'ShippingService')">
                  {{ row.shippingService || "N/A" }}
                </div>
                <div v-if="hasPermission('LimitRead', 'Orders', 'OrderShippingWeight')">{{ row.orderShippingWeight | formatNumber }} kg</div>
              </template>
              <template #orderType="{ row }">
                <div v-if="hasPermission('LimitRead', 'Orders', 'OrderType')" class="cell-order-type">
                  {{ row.orderType }}
                </div>
              </template>
              <template #orderDate="{ row }">
                <div class="cell-order-date">
                  <span v-if="hasPermission('LimitRead', 'Orders', 'OrderDate')">{{ row.orderDate | formatDateTime }} <br /></span>
                  <span v-if="hasPermission('LimitRead', 'Orders', 'ShippedDate')">{{ row.shippedDate | formatDateTime }}</span>
                </div>
              </template>
            </v-server-table>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <modal v-if="editModal" @close="hideEditModal" class-name="orders-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("orders.editOrder") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <tabs :tab-name="[$t('orders.order'), $t('orders.sku')]" color-button="success" content-flex-direction-column>
          <template slot="tab-pane-1">
            <ValidationObserver v-slot="{}" ref="editForm">
              <md-field v-if="hasPermission('Read', 'Orders', 'CustomerInvoiceNumber')">
                <label>{{ $t("orders.customerInvoiceNumber") }}</label>
                <md-input v-model="form.customerInvoiceNumber" type="text"></md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'CustomerIdNumber')">
                <label>{{ $t("orders.customerIdNumber") }}</label>
                <md-input v-model="form.customerIdNumber" type="text"> </md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'CustomerOrderId')">
                <label>{{ $t("orders.customerOrderId") }}</label>
                <md-input v-model="form.customerOrderId" type="text"> </md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'ShippingStatusId')">
                <label for="orderStatus">{{ $t("orders.status") }}</label>
                <md-select v-model="form.shippingStatusId" name="shippingStatusId" id="shippingStatusId">
                  <md-option :key="i.id" v-for="i in shippingStatusOptions" :value="i.id">{{ i.name }}</md-option>
                </md-select>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'pickType')">
                <label>{{ $t("orders.pickType") }}</label>
                <md-select v-model="form.pickType" name="pickTypeId" id="pickTypeId">
                  <md-option :key="i.id" v-for="i in pickTypes" :value="i.id">{{ i.name }}</md-option>
                </md-select>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'ShippingAddressCompany')">
                <label>{{ $t("orders.shippingAddressCompany") }}</label>
                <md-input v-model="form.shippingAddressCompany" type="text"> </md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'ShippingAddressName')">
                <label>{{ $t("orders.shippingAddressName") }}</label>
                <md-input v-model="form.shippingAddressName" type="text"> </md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'ShippingAddressStreet')">
                <label>{{ $t("orders.shippingAddressStreet") }}</label>
                <md-input v-model="form.shippingAddressStreet" type="text"> </md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'ShippingAddressZipcode')">
                <label>{{ $t("orders.shippingAddressZipcode") }}</label>
                <md-input v-model="form.shippingAddressZipcode" type="text"> </md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'ShippingAddressTown')">
                <label>{{ $t("orders.shippingAddressTown") }}</label>
                <md-input v-model="form.shippingAddressTown" type="text"> </md-input>
              </md-field>
              <md-field v-if="hasPermission('Read', 'Orders', 'ShippingAddressCountry')">
                <label>{{ $t("orders.shippingAddressCountry") }}</label>
                <md-input v-model="form.shippingAddressCountry" type="text"> </md-input>
              </md-field>
            </ValidationObserver>
          </template>
          <template slot="tab-pane-2">
            <ul v-if="hasPermission('Read', 'Orders', 'OrderSkus') && form.orderSkus" style="list-style: none">
              <li style="text-align: left" v-for="s in form.orderSkus" :key="s.id">
                <strong>{{ s.shippingSkuAmount }} x {{ s.shippingSkuName }}</strong
                >{{ " " }} <span v-if="s.shippingSkuCarrier || s.shippingSkuLocation">({{ s.shippingSkuCarrier }}, {{ parseShippingSkuLocation(s.shippingSkuLocation, s.id, s) }})</span
                ><span @click="removeSku(s.id)"><md-icon>delete</md-icon></span>
              </li>
            </ul>
            <div v-if="hasPermission('Read', 'Orders', 'OrderSkus')" style="text-align: left; color: #4caf50 !important; cursor: pointer">
              <span @click="addSkuModal = true">{{ $t("orders.addSku") }}</span>
            </div>
          </template>
        </tabs>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideEditModal">{{ $t("orders.close") }}</md-button>
        <md-button class="md-success" @click="editOrder">{{ $t("orders.save") }}</md-button>
      </template>
    </modal>

    <modal v-if="addSkuModal" @close="hideAddSkuModal" class-name="orders-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("orders.addSku") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideAddSkuModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <form @submit.prevent="addSku">
          <div class="md-layout">
            <ValidationObserver ref="addSkuModal" tag="div" class="md-layout-item">
              <ValidationProvider name="addSkuModal.shippingSkuAmount" :rules="shippingSkuName ? `required|max_value:${shippingSkuInventory}` : 'required'" v-slot="{ errors }">
                <md-field>
                  <label for="shippingSkuAmount">{{ $t("orders.shippingSkuAmount") }}</label>
                  <md-input v-model="shippingSkuAmount" required type="sku"></md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="addSkuModal.shippingSkuCarrier" :rules="'required'" v-slot="{ errors }">
                <md-field>
                  <label for="shippingSkuCarrier">{{ $t("orders.shippingSkuCarrier") }}</label>
                  <md-select v-model="shippingSkuCarrier" name="shippingSkuCarrier" id="shippingSkuCarrier">
                    <md-option :key="i.id" v-for="i in carriers" :value="i.name">{{ i.name }}</md-option>
                  </md-select>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>

              <ValidationProvider name="addSkuModal.shippingSkuInventoryObj" :rules="'required'" v-slot="{ errors }">
                <div class="wrap-v-select custom-wrap-v-select mt-4 mb-4" style="text-align: left; margin-bottom: 0">
                  <v-select
                    :filterable="false"
                    :options="inventories"
                    :placeholder="$t('orders.shippingSkuName') + ' & ' + $t('orders.shippingSkuLocation')"
                    @search="onSearchInvetories"
                    v-model="shippingSkuInventoryObj"
                    class="custom-select"
                    required
                  >
                    <template slot="no-options"> no record... </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center" v-html="option.name"></div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center" v-html="option.name"></div>
                    </template>
                  </v-select>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </div>
              </ValidationProvider>
            </ValidationObserver>
          </div>
          <div class="mt-4">
            <md-button class="md-secondary mr-1" @click="hideAddSkuModal">{{ $t("orders.cancel") }}</md-button>
            <md-button class="md-success" type="submit">{{ $t("orders.add") }}</md-button>
          </div>
        </form>
      </template>
      <template slot="footer"> </template>
    </modal>

    <modal v-if="setStatusModal" @close="hideSetStatusModal" class-name="orders-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("orders.setStatus") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideSetStatusModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <p>{{ $t("orders.messages.shipped") }}</p>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideSetStatusModal">{{ $t("orders.close") }}</md-button>
        <md-button class="md-success mr-1" @click="setOrdersStatus">{{ $t("orders.save") }}</md-button>
      </template>
    </modal>

    <modal v-if="deleteModal" @close="hideDeleteModal" class-name="orders-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("orders.setStatus") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideDeleteModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <p>
          {{ $t("orders.messages.delete") }}
        </p>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideDeleteModal">{{ $t("orders.close") }}</md-button>
        <md-button class="md-danger mr-1" @click="setOrdersStatusDeleted">{{ $t("orders.deleted") }}</md-button>
      </template>
    </modal>

    <modal v-if="exportModal" @close="hideExportModal" class-name="orders-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("orders.export") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideExportModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <p>Export the selected orders? <strong>Do you want to process?</strong></p>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideExportModal">{{ $t("orders.close") }}</md-button>
        <md-button class="md-success" @click="handleCsvGenerate()">{{ $t("orders.triggerExport") }}</md-button>
      </template>
    </modal>

    <modal v-if="addManualOrderModal" @close="hideAddManualOrderModal" class-name="orders-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("orders.addOrder") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideAddManualOrderModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <tabs :tab-name="[$t('orders.order'), $t('orders.sku')]" color-button="success" content-flex-direction-column>
          <template slot="tab-pane-1">
            <ValidationObserver v-slot="{}" ref="addManualForm">
              <ValidationProvider name="manualOrder.customerInvoiceNumber" rules="required" v-slot="{ errors }">
                <md-field>
                  <label>{{ $t("orders.customerInvoiceNumber") }}</label>
                  <md-input v-model="manualOrder.customerInvoiceNumber" type="text"></md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>

              <ValidationProvider name="manualOrder.customerIdNumber" rules="required" v-slot="{ errors }">
                <md-field>
                  <label>{{ $t("orders.customerIdNumber") }}</label>
                  <md-input v-model="manualOrder.customerIdNumber" type="text"></md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="manualOrder.customerOrderId" rules="required" v-slot="{ errors }">
                <md-field>
                  <label>{{ $t("orders.customerOrderId") }}</label>
                  <md-input v-model="manualOrder.customerOrderId" type="text"></md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <md-field>
                <label for="orderStatus">{{ $t("orders.status") }}</label>
                <md-select v-model="manualOrder.shippingStatusId" name="shippingStatusId" id="shippingStatusId">
                  <md-option :key="i.id" v-for="i in shippingStatusOptions" :value="i.id">{{ i.name }}</md-option>
                </md-select>
              </md-field>
              <md-field>
                <label>{{ $t("orders.shippingAddressCompany") }}</label>
                <md-input v-model="manualOrder.shippingAddressCompany" type="text"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("orders.shippingAddressName") }}</label>
                <md-input v-model="manualOrder.shippingAddressName" type="text"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("orders.shippingAddressStreet") }}</label>
                <md-input v-model="manualOrder.shippingAddressStreet" type="text"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("orders.shippingAddressZipcode") }}</label>
                <md-input v-model="manualOrder.shippingAddressZipcode" type="text"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("orders.shippingAddressTown") }}</label>
                <md-input v-model="manualOrder.shippingAddressTown" type="text"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("orders.shippingAddressCountry") }}</label>
                <md-input v-model="manualOrder.shippingAddressCountry" type="text"> </md-input>
              </md-field>
            </ValidationObserver>
          </template>
          <template slot="tab-pane-2">
            <ul v-if="manualOrder.orderSkus" style="list-style: none">
              <li style="text-align: left" v-for="s in manualOrder.orderSkus" :key="s.id">
                <strong>{{ s.shippingSkuAmount }} x {{ s.shippingSkuName }}</strong>
                <span v-if="s.shippingSkuCarrier || s.shippingSkuLocation">({{ s.shippingSkuCarrier }}, {{ parseShippingSkuLocation(s.shippingSkuLocation, s.id, s) }})</span
                ><span @click="removeManualSku(s.id)"><md-icon>delete</md-icon></span>
              </li>
            </ul>
            <div style="text-align: left; color: #4caf50 !important; cursor: pointer">
              <span
                @click="
                  addManualSkuModal = true;
                  shippingSkuAmount = 0;
                  shippingSkuName = '';
                  shippingSkuCarrier = '';
                  shippingSkuLocation = '';
                "
                >{{ $t("orders.addSku") }}</span
              >
            </div>
          </template>
        </tabs>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideAddManualOrderModal">{{ $t("orders.close") }}</md-button>
        <md-button class="md-success" @click="addOrder">{{ $t("orders.save") }}</md-button>
      </template>
    </modal>

    <modal v-if="addManualSkuModal" @close="hideAddManualSkuModal" class-name="orders-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("orders.addSku") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideAddManualSkuModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <div class="md-layout">
          <ValidationObserver ref="addManualSkuModal" tag="div" class="md-layout-item">
            <ValidationProvider name="addManualSkuModal.shippingSkuAmount" :rules="shippingSkuName ? `max_value:${shippingSkuInventory}` : ''" v-slot="{ errors }">
              <md-field>
                <label for="shippingSkuAmount">{{ $t("orders.shippingSkuAmount") }}</label>
                <md-input v-model="shippingSkuAmount" type="sku"></md-input>
                <div class="md-error" v-if="errors[0]">
                  {{ $t(errors[0]) }}
                </div>
              </md-field>
            </ValidationProvider>
            <ValidationProvider name="addSkuModal.shippingSkuCarrier" :rules="'required'" v-slot="{ errors }">
              <md-field>
                <label for="shippingSkuCarrier">{{ $t("orders.shippingSkuCarrier") }}</label>
                <md-select v-model="shippingSkuCarrier" name="shippingSkuCarrier" id="shippingSkuCarrier">
                  <md-option :key="i.id" v-for="i in carriers" :value="i.name">{{ i.name }}</md-option>
                </md-select>
                <div class="md-error" v-if="errors[0]">
                  {{ $t(errors[0]) }}
                </div>
              </md-field>
            </ValidationProvider>
          </ValidationObserver>

          <div class="md-layout-item"></div>
          <div class="md-layout-item md-size-100 mt-4">
            <v-select
              :filterable="false"
              :options="inventories"
              :placeholder="$t('orders.shippingSkuName') + ' & ' + $t('orders.shippingSkuLocation')"
              @search="onSearchInvetories"
              @input="onSelectInventory"
            >
              <template slot="no-options"> no record... </template>
              <template slot="option" slot-scope="option">
                <div class="d-center" v-html="option.name"></div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center" v-html="option.name"></div>
              </template>
            </v-select>
            <!-- <md-field>
              <label for="shippingSkuCarrier">{{ $t("orders.shippingSkuCarrier") }}</label>
              <md-input v-model="shippingSkuCarrier"></md-input>
            </md-field> -->
          </div>
        </div>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideAddManualSkuModal">{{ $t("orders.close") }}</md-button>
        <md-button class="md-success" @click="addManualSku">{{ $t("orders.save") }}</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import numeral from "numeral";
import VueJsonToCsv from "vue-json-to-csv-async/src/JsonToCsv";

import { Modal, Tabs } from "@/components";
import SimpleDateTimePicker from "@/components/DateTimePicker/SimpleDateTimePicker.vue";
import CSLoading from "../../components/Loading/CSLoading.vue";
import axiosFactory from "../../services/axios.factory";
import { EventBus } from "@/helpers/event-bus";
import stickyTableHeader from "@/helpers/stickyTableHeader";
import { hasPermission, assignedWarehouse } from "../../mixins/auth";
import { normalizeStatusName } from "../../mixins/formats";

export default {
  components: {
    CSLoading,
    Modal,
    Tabs,
    SimpleDateTimePicker,
    "vue-json-to-csv": VueJsonToCsv,
  },
  data() {
    const userWarehouse = assignedWarehouse();
    return {
      isLoading: true,
      editModal: false,
      createModal: false,
      addSkuModal: false,
      setStatusModal: false,
      deleteModal: false,
      exportModal: false,
      addManualOrderModal: false,
      addManualSkuModal: false,
      orderFile: null,
      files: {
        orderFile: null,
      },
      shippingStatusId: null,
      shippingSkuAmount: null,
      shippingSkuName: null,
      shippingSkuCarrier: null,
      shippingSkuLocation: null,
      shippingSkuInventory: null,
      shippingSkuInventoryObj: null,
      form: {},
      manualOrder: {
        orderSkus: [],
      },
      enableBulky: false,
      filterBulky: false,
      filter: {
        keywords: null,
        warehouseId: userWarehouse ? userWarehouse.id : null,
        shippingStatusId: 1,
        bulky: null,
        pickTypes: null,
      },
      tableColumns: ["actions", "status", "orderType", "customer", "shipping", "sku", "carrier", "orderDate"],
      tableOptions: {
        headings: {
          actions: () => this.$t("orders.table.actions"),
          customer: () => this.$t("orders.table.customer"),
          shipping: () => this.$t("orders.table.shipping"),
          sku: () => this.$t("orders.table.sku"),
          status: () => this.$t("orders.table.status"),
          carrier: () => this.$t("orders.table.carrier"),
          orderType: () => this.$t("orders.table.orderType"),
          orderDate: () => this.$t("orders.table.orderDate"),
        },
        perPage: 100,
        perPageValues: [100, 200, 500, 1000],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        requestFunction: this.requestFunction,
        responseAdapter: this.responseFunction,
        rowClassCallback: this.rowClassCallback,
        pagination: {
          show: true,
        },
        texts: {
          count: this.$t("table.count"),
          limit: this.$t("table.records"),
          noResults: this.$t("table.noRecord"),
        },
        columnsClasses: {
          shipping: "mw-400",
          customer: "text-left",
          shipping: "text-left",
          sku: "text-left",
        },
      },
      tableData: [],
      totalItems: 0,
      totalSelectedItems: 0,
      currentPage: 1,
      table: null,
      isCheckAll: false,
      selectedItems: new Set(),
      deselectedItems: new Set(),
      selectedExports: new Map(),
      shippingStatusOptions: [],
      productId: "",
      products: [],
      inventories: [],
      selectedInventories: [],
      pickTypes: [
        { id: "na", name: "N/A" },
        { id: "SQP", name: this.$t("orders.pickTypes.SQP") },
        { id: "CAB", name: this.$t("orders.pickTypes.CAB") },
        { id: "CHECK", name: this.$t("orders.pickTypes.CHECK") },
      ],
    };
  },
  computed: {
    ...mapState("warehouses", {
      stateStorageLocations: "storageLocations",
      warehouseOptions: "warehouses",
    }),
    ...mapState("shippingData", ["carriers"]),
    storageLocations() {
      return this.stateStorageLocations || [];
    },
    hasCheckedAll() {
      return this.totalItems > 0 && this.totalSelectedItems === this.totalItems;
    },
    isWarehouseAssigned() {
      return !!assignedWarehouse();
    },
  },
  watch: {
    shippingSkuInventoryObj(val) {
      this.onSelectInventory(val);
    },
    enableBulky(bool) {
      if (!bool) {
        this.filter.bulky = null;
      } else {
        if (this.filterBulky) {
          this.filter.bulky = "true";
        } else {
          this.filter.bulky = "false";
        }
      }

      this.debounceApplyFilters();
    },
    filterBulky(bool) {
      if (bool) {
        this.filter.bulky = "true";
      } else {
        this.filter.bulky = "false";
      }

      this.debounceApplyFilters();
    },
  },
  methods: {
    hideEditModal() {
      this.editModal = false;
    },
    showEditModal(item) {
      this.editModal = true;
      this.form = structuredClone({ ...item });
    },
    hideCreateModal() {
      this.createModal = false;
    },
    hideAddSkuModal() {
      this.addSkuModal = false;

      this.resetSkuModal();
    },
    hideSetStatusModal() {
      this.setStatusModal = false;
    },
    hideDeleteModal() {
      this.deleteModal = false;
    },
    hideExportModal() {
      this.exportModal = false;
    },
    hideAddManualOrderModal() {
      this.addManualOrderModal = false;
    },
    hideAddManualSkuModal() {
      this.addManualSkuModal = false;
      this.resetSkuModal();
    },
    debounceApplyFilters: debounce(function () {
      this.applyFilters({});
    }, 300),
    applyFilters(filters) {
      stickyTableHeader({ selector: "#table-orders" });
      const pickTypes = this.filter.pickTypes ? this.filter.pickTypes.join(",") : null;
      this.$refs.table.options.params = {
        ...this.$refs.table.options.params,
        ...this.filter,
        ...filters, //
        pickTypes: pickTypes,
        // pageIndex: 0,
      };
      this.handleCheckAll(false);
      this.currentPage = 1;
      this.$refs.table.setPage(this.currentPage);
    },
    requestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      const params = {
        ...this.filter,
        ...filters,
        pageIndex: this.currentPage,
        pageSize: limit,
        orderBy,
        ascending,
      };
      if (!params.hasOwnProperty("shippingStatusId")) {
        params.shippingStatusId = 1;
      }
      if (!params.warehouseId) {
        params.warehouseId = undefined;
      }

      this.isLoading = true;
      return axiosFactory()
        .get(`/orders`, { params })
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    responseFunction({ data }) {
      this.totalItems = data.total;
      const selected = !!this.isCheckAll;
      this.tableData = data.data.map((i, index) => {
        if (this.deselectedItems.has(i.id)) {
          this.selectedExports.delete(i.id);
          return { ...i, selected: false, index };
        }

        if (selected) {
          this.selectedItems.add(i.id);
          this.selectedExports.set(i.id, i);
        }
        return { ...i, selected, index };
      });

      return {
        data: this.tableData,
        count: data.total,
      };
    },
    rowClassCallback(row) {
      if (row.shippingStatusName === "unshipped") {
        return "shipping-status-unshipped";
      }

      if (row.shippingStatusName === "shipped") {
        return "shipping-status-shipped";
      }

      if (row.shippingStatusName === "shipping number included") {
        return "shipping-status-shipping-number-included";
      }

      if (row.shippingStatusName === "exported") {
        return "shipping-status-exported";
      }

      if (row.shippingStatusName === "error") {
        return "shipping-status-error";
      }

      return "";
    },
    addOrder() {
      this.isLoading = true;
      const inputs = this.prepareFormData(this.manualOrder);
      axiosFactory()
        .post(`/orders`, inputs)
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.added"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .then(() => {
          this.hideAddManualOrderModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    prepareFormData(rawData) {
      const skus = rawData.orderSkus.map((sku) => {
        const parts = sku.shippingSkuLocation.split(":");
        const shippingSku = sku.id ? sku.shippingSkuLocation : parts[parts.length - 1];
        return {
          ...sku,
          shippingSkuLocation: shippingSku,
        };
      });

      const formData = { ...rawData, orderSkus: skus };

      return formData;
    },
    editOrder() {
      this.isLoading = true;
      const inputs = this.prepareFormData(this.form);
      axiosFactory()
        .put(`/orders/${this.form.id}`, inputs)
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.updated"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .then(() => {
          this.hideEditModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    copy(orderId) {
      this.isLoading = true;
      axiosFactory()
        .post(`/orders/copy/${orderId}`)
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.copied"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .then(() => {
          this.hideEditModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    removeManualSku(orderSkuId) {
      const objWithIdIndex = this.manualOrder.orderSkus.findIndex((obj) => obj.id === orderSkuId);

      if (objWithIdIndex > -1) {
        this.manualOrder.orderSkus.splice(objWithIdIndex, 1);
      }

      this.manualOrder = Object.assign({}, this.manualOrder);
    },
    async addManualSku() {
      if (this.$refs.addManualSkuModal) {
        const isValid = await this.$refs.addManualSkuModal.validate();
        if (!isValid) {
          return;
        }
      }

      this.manualOrder.orderSkus &&
        this.manualOrder.orderSkus.push({
          shippingSkuAmount: this.shippingSkuAmount,
          shippingSkuName: this.shippingSkuName,
          shippingSkuCarrier: this.shippingSkuCarrier,
          shippingSkuLocation: this.shippingSkuLocation,
        });
      this.addManualSkuModal = false;
    },
    removeSku(orderSkuId) {
      const objWithIdIndex = this.form.orderSkus.findIndex((obj) => obj.id === orderSkuId);

      if (objWithIdIndex > -1) {
        this.form.orderSkus.splice(objWithIdIndex, 1);
      }

      this.form = Object.assign({}, this.form);
    },
    async addSku() {
      if (this.$refs.addSkuModal) {
        const isValid = await this.$refs.addSkuModal.validate();
        if (!isValid) {
          return;
        }
      }

      const skuItem = {
        shippingSkuAmount: this.shippingSkuAmount,
        shippingSkuName: this.shippingSkuName,
        shippingSkuCarrier: this.shippingSkuCarrier,
        shippingSkuLocation: this.shippingSkuLocation,
      };
      // check if existed
      const exited = this.form.orderSkus?.findIndex((a) => a.shippingSkuLocation === skuItem.shippingSkuLocation);
      if (exited >= 0) {
        const totalAmt = parseInt(this.form.orderSkus[exited].shippingSkuAmount) + parseInt(skuItem.shippingSkuAmount);
        this.form.orderSkus[exited].shippingSkuAmount = totalAmt.toString();
      } else {
        this.form.orderSkus && this.form.orderSkus.push(skuItem);
      }

      this.addSkuModal = false;
      this.resetSkuModal();
    },
    resetSkuModal() {
      this.shippingSkuAmount = null;
      this.shippingSkuName = null;
      this.shippingSkuCarrier = null;
      this.shippingSkuLocation = null;
      this.shippingSkuInventoryObj = null;
      this.shippingSkuInventory = null;
      this.inventories = [];
    },
    handleSelectRow(row) {
      row.selected = !row.selected;
      if (row.selected) {
        this.totalSelectedItems += 1;
        this.selectedItems.add(row.id);
        this.deselectedItems.delete(row.id);

        this.selectedExports.set(row.id, row);
      } else {
        this.totalSelectedItems -= 1;
        this.selectedItems.delete(row.id);
        this.deselectedItems.add(row.id);

        this.selectedExports.delete(row.id);
      }

      if (this.$refs.checkAllInput) {
        this.$refs.checkAllInput.indeterminate = this.totalSelectedItems > 0 && this.totalSelectedItems !== this.totalItems;
      }
    },
    handleCheckAll(forceBool) {
      if (typeof forceBool === "boolean") {
        this.isCheckAll = forceBool;
      } else {
        if (!this.hasCheckedAll) {
          this.isCheckAll = true;
        } else if (this.hasCheckedAll && !this.isCheckAll) {
          this.isCheckAll = false;
        } else {
          this.isCheckAll = !this.isCheckAll;
        }
      }

      if (this.isCheckAll) {
        this.totalSelectedItems = +this.totalItems;
      } else {
        this.totalSelectedItems = 0;
        this.selectedItems.clear();
        this.selectedExports.clear();
      }

      this.deselectedItems.clear();

      for (let index = 0; index < this.tableData.length; index++) {
        this.tableData[index].selected = !!this.isCheckAll;
        if (this.isCheckAll) {
          this.selectedItems.add(this.tableData[index].id);
          this.selectedExports.set(this.tableData[index].id, this.tableData[index]);
        }
      }
    },
    async handleFetchSelectedItems() {
      if (!this.isCheckAll) {
        return Promise.resolve();
      }

      const isLocalFull = this.totalItems === this.selectedItems.size;
      if (isLocalFull) {
        return Promise.resolve();
      }

      try {
        this.isLoading = true;
        const params = { ...this.filter, pageSize: 10000000 };
        const { data: resp } = await axiosFactory().get("/orders", { params });
        for (let index = 0; index < resp.data.length; index++) {
          if (!this.deselectedItems.has(resp.data[index].id)) {
            this.selectedItems.add(resp.data[index].id);
            this.selectedExports.set(resp.data[index].id, resp.data[index]);
          }
        }
      } finally {
        this.isLoading = false;
      }
    },
    async setOrdersStatus() {
      await this.handleFetchSelectedItems();

      const payload = {
        orderIds: Array.from(this.selectedItems),
      };

      this.isLoading = true;
      axiosFactory()
        .put("/orders/set_orders_status", payload, { responseType: "blob" })
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.statusUpdated"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          const now = new Date();
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `flatfile-${now.toISOString()}.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideSetStatusModal();
          this.isLoading = false;
        });
    },
    async setOrdersStatusDeleted() {
      await this.handleFetchSelectedItems();

      const payload = {
        orderIds: Array.from(this.selectedItems),
      };

      this.isLoading = true;
      axiosFactory()
        .put("/orders/set_orders_status_deleted", payload)
        .then(() => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.statusUpdated"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideDeleteModal();
          this.isLoading = false;
        });
    },
    handleFileUpload(key, event) {
      this.files[key] = event.target.files[0];
    },
    importOrderFile() {
      this.isLoading = true;
      const formData = new FormData();
      if (this.files.orderFile) {
        formData.append("orderFile", this.files.orderFile);
      }
      axiosFactory()
        .post(`/orders/import`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.fileImported"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });

          this.applyFilters({});
        })
        .then(() => {
          this.hideExportModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    dateFilterChanaged(d, key) {
      this.filter[key] = d.startDate;
      this.applyFilters({});
    },
    clearDatetime(key) {
      this.filter[key] = null;
      this.applyFilters({});
    },
    parseShippingSkuLocation(shippingSkuLocation, orderSkuId, shippingRef) {
      if (!shippingSkuLocation) return "";

      if (shippingSkuLocation.toUpperCase() === "VS") {
        return "PP";
      }

      const locations = [];
      try {
        const parts = shippingSkuLocation.split(";");
        for (let index = 0; index < parts.length; index++) {
          const element = parts[index];
          const inventoryParts = element.split(":");
          let locationId = parseInt(inventoryParts[0]);
          let location = this.storageLocations.find((l) => l.id === locationId);

          let inventory = parseInt(inventoryParts[1]);
          if ((!inventory || isNaN(inventory)) && shippingRef && this.selectedInventories.length) {
            console.log("parseShippingSkuLocation", location, inventoryParts, shippingRef, this.selectedInventories, "---", this.storageLocations, location);
            const item = this.selectedInventories.find((i) => i.sku === shippingRef.shippingSkuName);
            inventory = item ? item.inventory : "";
          }

          if (location) {
            locations.push(`${location.fullName}:${inventory}`);
          }
        }
      } catch (e) {
        console.log(e);
      }

      return locations.join(",");
    },
    onSelectInventory(selected) {
      if (!selected || !selected.raw) {
        return;
      }
      const item = { ...selected.raw };
      this.selectedInventories.push(item);
      this.shippingSkuInventory = item.inventory - item.reserved; // item.inventory;

      this.shippingSkuName = item.sku;
      // Please don't change it
      // this.shippingSkuLocation = `${item.id}`;
      this.shippingSkuLocation = `${item.storageLocationId}:${item.inventory}:${item.id}`;
    },
    onSearchInvetories(search, loading) {
      if (search.length) {
        loading(true);
        this.searchInventory(loading, search, this);
      }
    },
    searchInventory: debounce((loading, search, vm) => {
      axiosFactory()
        .get(`/warehouses/inventories?keywords=${search}&pageIndex=0&pageSize=100&isAvailable=true`)
        .then((res) => {
          vm.inventories = res.data.data.map((p) => ({
            //
            value: p.id,
            // name: `${p.sku} (${p.warehouseCode}-${p.storageLocation}:${p.inventory})`,
            name: `${p.sku} (${p.warehouseCode}-${p.storageLocation} | ${vm.$t("wms.inventory")}: <strong>${p.inventory}</strong> | ${vm.$t("wms.reserved")}: ${p.reserved})`,
            label: `${p.sku} (${p.warehouseCode}-${p.storageLocation}:${p.inventory})`,
            raw: { ...p },
          }));
          loading(false);
        });
    }, 300),
    updateInventory() {
      if (!confirm(this.$t("orders.messages.confirmUpdateInventory"))) {
        return;
      }

      this.isLoading = true;
      axiosFactory()
        .put(`/orders/flush_reserved`)
        .then((res) => {
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.inventoryUpdated"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((err) => {
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.inventoryOutOfStock"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async exportOrders() {
      try {
        await this.handleFetchSelectedItems();

        this.isLoading = true;

        const filtered = Array.from(this.selectedExports.values());
        const items = [];

        for (let i = 0; i < filtered.length; i++) {
          let { title, shippingStatusId, skus, orderSkus, ...data } = filtered[i];
          const skuStr = [];
          if (orderSkus && orderSkus.length > 0) {
            for (let index = 0; index < orderSkus.length; index++) {
              const sku = orderSkus[index];
              let str = `${sku.shippingSkuAmount} x ${sku.shippingSkuName}`;
              if (sku.shippingSkuCarrier || sku.shippingSkuLocation) {
                str = str + ` (${sku.shippingSkuCarrier}, ${this.parseShippingSkuLocation(sku.shippingSkuLocation)})`;
              }
              skuStr.push(str);
            }
          }
          items.push({ ...data, orderSkus: skuStr.join(";") });
        }

        return items;
      } catch (error) {
        console.error(error);
        return [];
      } finally {
        this.exportModal = false;
        this.isLoading = false;
      }
    },
    handleCsvGenerate() {
      if (!this.$refs.csvGenerator) {
        return;
      }

      this.$refs.csvGenerator.handleClick();
    },
    getProducts(keywords) {
      axiosFactory()
        .get(`/shipping_data/products?keywords=${keywords}`)
        .then((res) => {
          this.products = res.data.data.map((p) => p.sku);
        });
    },
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    getI18N(prefix, statusName) {
      return this.$t(`${prefix}.${normalizeStatusName(statusName)}`);
    },
  },
  created() {
    this.$store.dispatch("shippingData/getShippingStatuses").then((res) => {
      this.shippingStatusOptions = [{ id: 0, name: "" }, ...res];
    });
    this.$store.dispatch("shippingData/getCarriers");
    this.$store.dispatch("warehouses/getStorageLocations");
    this.$store.dispatch("warehouses/getWarehouses");
  },
  mounted() {
    stickyTableHeader({ selector: "#table-orders" });
    EventBus.$on("language:changed", ({ locale }) => {
      numeral.locale(locale);
      this.applyFilters({});
    });
  },
  updated() {
    EventBus.$emit("scroll:changed");
  },
  beforeDestroy() {
    EventBus.$off("language:changed");
    EventBus.$off("scroll:changed");
  },
};
</script>
<style lang="css" src="./Orders.css"></style>
<style>
.md-menu-content-bottom-start {
  z-index: 10001;
}
</style>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mr-1 {
  margin-right: 15px;
}

.mt-0 {
  margin-top: 0 !important;
}

.shipping-bulky {
  cursor: default;
  line-height: 24px;
  vertical-align: middle;
}

.documents i {
  color: #47a44b !important;
}
.md-success {
  background-color: #47a44b !important;
}
.empty-product {
  background-color: gray;
}

.date-title {
  margin-bottom: 0;
  text-align: left;
  font-size: 0.85em;
  color: #888;
}

.tr-footer-shippping-plan td {
  background-color: darkgray;
}

.order-pick-type-SQP {
  background-color: #a8d5ba !important;
  border-color: #a8d5ba;
  color: #000 !important;
}
.order-pick-type-CAB {
  background-color: #b0e0e6 !important;
  border-color: #b0e0e6;
  color: #000 !important;
}
.order-pick-type-CHECK {
  background-color: #fff9c4 !important;
  border-color: #ffa726;
  color: #000 !important;
}
.cell-sku ul {
  margin: 0;
  padding-left: 15px;
}
.address-item {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  i {
    font-size: 15px !important;
    height: 15px;
    width: 15px;
    color: #999 !important;
    margin: 0;
  }
}
</style>
