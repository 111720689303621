<template>
  <div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }, { rtl: $route.meta.rtlActive }]">
    <notifications></notifications>
    <side-bar :active-color="sidebarBackground" :background-image="sidebarBackgroundImage" :data-background-color="sidebarBackgroundColor">
      <!-- <user-menu :title="user.firstName"></user-menu> -->
      <!-- <mobile-menu></mobile-menu> -->
      <template slot="links">
        <sidebar-item :link="{ name: $t('layout.dashboard'), icon: 'dashboard', path: '/app/dashboard' }"> </sidebar-item>
        <sidebar-item v-if="canView('ShippingPlans')" :link="{ name: $t('layout.shippingPlans'), icon: 'local_shipping', path: '/app/shipping-plans' }"> </sidebar-item>
        <sidebar-item v-if="canView('Products')" :link="{ name: $t('layout.products'), icon: 'package_2', iconOutlined: true, path: '/app/products' }"> </sidebar-item>
        <sidebar-item v-if="canView('Orders')" :link="{ name: $t('layout.orders'), icon: 'grade', iconOutlined: false, path: '/app/orders' }"> </sidebar-item>
        <sidebar-item
          v-if="canView('Suppliers')"
          :link="{
            name: $t('layout.suppliers'),
            icon: 'precision_manufacturing',
            iconOutlined: true,
            path: '/app/suppliers',
          }"
        >
        </sidebar-item>
        <sidebar-item :link="{ name: $t('layout.wms.wms'), disableCollapse: true, icon: 'warehouse' }">
          <sidebar-item :link="{ name: $t('layout.wms.dashboard'), icon: 'dashboard', path: '/app/wms-dashboard' }" />
          <sidebar-item v-if="canView('Inventories')" :link="{ name: $t('layout.wms.inventory'), icon: 'inventory_2', path: '/app/wms-inventories' }" />
          <sidebar-item v-if="canView('Warehouses')" :link="{ name: $t('layout.wms.warehouses'), icon: 'shelves', path: '/app/wms-warehouses' }" />
          <sidebar-item
            v-if="canView('StorageLocations')"
            :link="{
              name: $t('layout.wms.storagelocations'),
              icon: 'home_storage',
              iconOutlined: true,
              path: '/app/wms-storage-locations',
            }"
          />
          <sidebar-item
            v-if="canView('InventoryManagementExport')"
            :link="{
              name: $t('layout.wms.fileExplorer'),
              icon: 'download',
              iconOutlined: false,
              path: '/app/wms-management-export',
            }"
          />
          <sidebar-item
            v-if="canView('WarehousesHistory')"
            :link="{
              name: $t('layout.wms.history'),
              icon: 'history',
              path: '/app/wms-history',
            }"
          />
        </sidebar-item>
        <sidebar-item v-if="isAdminUser" :link="{ name: $t('layout.users'), icon: 'people', path: '/app/users' }"> </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <TopNavbar></TopNavbar>
      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { isAdmin, canView } from "@/mixins/auth";
import TokenService from "../../../services/token.service";
import { EventBus } from "@/helpers/event-bus";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  // if (hasElement(className)) {
  //   new PerfectScrollbar(`.${className}`, { wheelSpeed: 0.09 });
  //   document.getElementsByClassName(className)[0].scrollTop = 0;
  // } else {
  //   // try to init it later in case this component is loaded async
  //   setTimeout(() => {
  //     initScrollbar(className);
  //   }, 100);
  // }
}

function reinitScrollbar() {
  // let docClasses = document.body.classList;
  // let isWindows = navigator.platform.startsWith("Win");
  // if (isWindows) {
  //   // if we are on windows OS we activate the perfectScrollbar function
  //   initScrollbar("sidebar");
  //   initScrollbar("sidebar-wrapper");
  //   initScrollbar("main-panel");
  //   docClasses.add("perfect-scrollbar-on");
  // } else {
  //   docClasses.add("perfect-scrollbar-off");
  // }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "/img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      user: TokenService.getUser(),
    };
  },
  computed: {
    isAdminUser() {
      return isAdmin();
    },
    menuPermissions() {
      return isAdmin();
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    canView(menu) {
      return isAdmin() || canView(menu);
    },
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
    EventBus.$on("scroll:changed", () => {
      reinitScrollbar();
    });
  },
  unmounted() {
    EventBus.$off("scrollbar:changed");
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.sidebar .nav .nav {
  padding-left: 15px;
}
</style>
