<template>
  <div class="table-sticky">
    <v-client-table :data="tableData" :columns="tableColumns" :options="tableOptions">
      <template #menuPermission="{ row }">
        <input type="checkbox" :checked="hasMenuPermission(row.menu)" @click="handleSelectMenuPermission(row.menu)" />
      </template>
      <template #readPermission="{ row }">
        <div class="align-left">
          <p class="read column">
            <input
              type="checkbox"
              :checked="hasPermission(row.menu, 'Read', '') || hasPermission(row.menu, 'Write', '')"
              :indeterminate="hasPermission(row.menu, 'LimitRead', '')"
              @click="handleSelectPermission(row.menu, 'Read', '')"
            />
            Read
          </p>
          <p class="column" :key="col" v-for="col in getTableColumns(row.menu)">
            <input type="checkbox" :checked="hasPermission(row.menu, 'LimitRead', col) || hasPermission(row.menu, 'LimitRead', 'ALL')" @click="handleSelectPermission(row.menu, 'LimitRead', col)" />
            {{ col }}
          </p>
        </div>
      </template>
      <template #writePermission="{ row }"
        ><div class="align-left">
          <p class="write column">
            <input type="checkbox" :checked="hasPermission(row.menu, 'Write', '')" @click="handleSelectPermission(row.menu, 'Write', '')" />
            Write
          </p>
          <div v-if="row.menu === 'Products'">
            <p class="column" :key="col" v-for="col in getTableColumns(row.menu)">
              <input type="checkbox" :checked="hasWritePermission(row.menu, 'Write', col)" @click="handleSelectWritePermission(row.menu, 'Write', col)" />
              {{ col }}
            </p>
          </div>
        </div>
      </template>
    </v-client-table>
  </div>
</template>

<script>
import { cloneDeep, remove } from "lodash";
import axiosFactory from "../../services/axios.factory";

export default {
  components: {},
  props: {
    userData: null,
  },
  data() {
    return {
      user: cloneDeep(this.userData),
      tableColumnPermissions: [],
      tableColumns: ["menu", "menuPermission", "readPermission", "writePermission"],
      tableData: [
        { menu: "Dashboard", menuPermission: true, readPermission: [], writePermission: false },
        { menu: "ShippingPlans", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Products", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Orders", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Suppliers", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Warehouses", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "StorageLocations", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Inventories", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "News", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "InventoryManagementExport", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "WarehousesHistory", menuPermission: true, readPermission: [], writePermission: false },
      ],
      tableOptions: {
        headings: {
          menu: () => this.$t("users.menu"),
          menuPermission: () => this.$t("users.menuPermission"),
          readPermission: () => this.$t("users.readPermission"),
          writePermission: () => this.$t("users.writePermission"),
        },
        columnsClasses: {
          menu: "text-left",
          menuPermission: "text-left",
          readPermission: "text-left",
          writePermission: "text-left",
        },
        perPage: 20,
        perPageValues: [20],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        pagination: {
          show: false,
        },
      },
    };
  },
  directives: {
    // In Vue 2.x
    indeterminate: function (el, binding) {
      el.indeterminate = Boolean(binding.value);
    },
  },
  computed: {
    roleOptions() {
      return this.$store.state.users.roles || [];
    },
    permissionOptions() {
      return ["LimitRead", "Read", "Write"];
    },
  },
  methods: {
    getTableColumns(menu) {
      if (this.tableColumnPermissions && this.tableColumnPermissions.hasOwnProperty(menu)) {
        return this.tableColumnPermissions[menu];
      }

      return [];
    },
    hasMenuPermission(menu) {
      return this.user && this.user.menuPermissions && this.user.menuPermissions.includes(menu);
    },
    handleSelectMenuPermission(menu) {
      if (this.user.menuPermissions.includes(menu)) {
        remove(this.user.menuPermissions, (p) => p === menu);
      } else {
        this.user.menuPermissions.push(menu);
      }

      this.user.menuPermissions = cloneDeep(this.user.menuPermissions);

      this.$emit("userPerimissionsUpdated", {
        tableColumnPermissions: this.user.tableColumnPermissions,
        menuPermissions: this.user.menuPermissions,
      });
    },
    hasPermission(table, permission, col) {
      if (!this.user) return false;

      if (!this.user.tableColumnPermissions[table]) return false;

      if (!this.user.tableColumnPermissions[table][permission]) return false;

      if (permission === "Read" || permission === "Write") {
        return this.user.tableColumnPermissions[table][permission].includes("ALL");
      }
      if (permission === "LimitRead" && col === "") {
        return this.user.tableColumnPermissions[table][permission].length > 0;
      }

      return this.user.tableColumnPermissions[table][permission].includes(col);
    },
    hasWritePermission(table, permission, col) {
      if (!this.user) return false;

      if (!this.user.tableColumnPermissions[table]) return false;

      if (!this.user.tableColumnPermissions[table][permission]) return false;

      return this.user.tableColumnPermissions[table][permission].includes(col);
    },
    toggleRelatedPermissions(table, permission, col, isChecked) {
      if (permission === "Write") {
        this.user.tableColumnPermissions[table]["Read"] = isChecked ? ["ALL"] : [];
      }
      if (permission === "Read" || permission === "Write") {
        if (isChecked) {
          this.user.menuPermissions.push(table);
        } else {
          remove(this.user.menuPermissions, (p) => p === table);
        }
        if (permission === "Read") {
          this.user.tableColumnPermissions[table]["Write"] = [];
        }
        if (isChecked) {
          this.user.tableColumnPermissions[table]["LimitRead"] = this.getTableColumns(table).filter((col) => col !== "ALL");
        } else {
          this.user.tableColumnPermissions[table]["LimitRead"] = [];
        }
      } else {
        if (!isChecked) {
          delete this.user.tableColumnPermissions[table]["Read"];
          delete this.user.tableColumnPermissions[table]["Write"];
        } else {
          if (this.user.tableColumnPermissions[table][permission].length === this.tableColumnPermissions[table].length) {
            this.user.tableColumnPermissions[table]["Read"] = ["ALL"];
          }
        }
      }
    },
    handleSelectPermission(table, permission, col) {
      if (permission === "Read" || permission === "Write") {
        if (!this.user.tableColumnPermissions[table]) {
          this.user.tableColumnPermissions[table] = {};
          this.user.tableColumnPermissions[table][permission] = ["ALL"];

          // select all other permissions with Write permission checked.
          this.toggleRelatedPermissions(table, permission, col, true);
        } else if (!this.user.tableColumnPermissions[table][permission]) {
          this.user.tableColumnPermissions[table][permission] = ["ALL"];
          this.toggleRelatedPermissions(table, permission, col, true);
          // remove permission if it is already checked
        } else if (this.user.tableColumnPermissions[table][permission].includes("ALL")) {
          this.user.tableColumnPermissions[table][permission] = [];
          this.toggleRelatedPermissions(table, permission, col, false);
        } else {
          this.user.tableColumnPermissions[table][permission] = ["ALL"];
          this.toggleRelatedPermissions(table, permission, col, true);
        }

        // this.user.tableColumnPermissions = cloneDeep(this.user.tableColumnPermissions);
      } else {
        if (!this.user.tableColumnPermissions[table]) {
          this.user.tableColumnPermissions[table] = {};
          this.user.tableColumnPermissions[table][permission] = [col];
          this.toggleRelatedPermissions(table, permission, col, true);
        } else if (!this.user.tableColumnPermissions[table][permission]) {
          this.user.tableColumnPermissions[table][permission] = [col];
          this.toggleRelatedPermissions(table, permission, col, true);
        } else if (this.user.tableColumnPermissions[table][permission].includes(col)) {
          remove(this.user.tableColumnPermissions[table][permission], (p) => p === col);
          this.toggleRelatedPermissions(table, permission, col, false);
        } else {
          this.user.tableColumnPermissions[table][permission].push(col);
          this.toggleRelatedPermissions(table, permission, col, true);
        }
      }

      this.user.tableColumnPermissions = cloneDeep(this.user.tableColumnPermissions);
      this.user.menuPermissions = cloneDeep(this.user.menuPermissions);

      this.$emit("userPerimissionsUpdated", {
        tableColumnPermissions: this.user.tableColumnPermissions,
        menuPermissions: this.user.menuPermissions,
      });
    },
    handleSelectWritePermission(table, permission, col) {
      if (!this.user.tableColumnPermissions[table]) {
        this.user.tableColumnPermissions[table] = {};
        this.user.tableColumnPermissions[table][permission] = ["ALL", col];
      } else if (!this.user.tableColumnPermissions[table][permission]) {
        this.user.tableColumnPermissions[table][permission] = ["ALL", col];
      } else if (this.user.tableColumnPermissions[table][permission].includes(col)) {
        this.user.tableColumnPermissions[table][permission] = this.user.tableColumnPermissions[table][permission].filter((i) => i !== col);
      } else {
        this.user.tableColumnPermissions[table][permission].push(col);
      }

      this.user.tableColumnPermissions = cloneDeep(this.user.tableColumnPermissions);
      this.user.menuPermissions = cloneDeep(this.user.menuPermissions);

      this.$emit("userPerimissionsUpdated", {
        tableColumnPermissions: this.user.tableColumnPermissions,
        menuPermissions: this.user.menuPermissions,
      });
    },
    updateUserPermissions(tableColumnPermissions) {
      const u = cloneDeep(this.userData);

      const userPermissions = Object.entries(u.tableColumnPermissions).reduce((acc, [table, permissions]) => {
        if (permissions?.Write?.includes("ALL")) {
          permissions.Read = ["ALL"];
        }
        let LimitRead = permissions?.LimitRead;
        const shouldMap = (permissions?.LimitRead?.includes("ALL") || permissions?.Read?.includes("ALL")) && !permissions?.LimitRead?.length;
        if (shouldMap) {
          const allColumns = this.getTableColumns(table);
          LimitRead = allColumns.map((col) => col);
        }
        acc[table] = {
          Read: permissions?.Read,
          Write: permissions?.Write,
          LimitRead: LimitRead,
        };

        return acc;
      }, {});

      this.user.tableColumnPermissions = userPermissions;
    },
  },
  created() {
    Promise.all([this.$store.dispatch("users/getUsers"), this.$store.dispatch("users/getRoles")]).finally((_) => {
      this.isLoading = false;
    });
    axiosFactory()
      .get(`/users/table_columns`)
      .then((res) => {
        this.tableColumnPermissions = res.data.data;
        this.updateUserPermissions(res.data.data);
      });
  },
};
</script>
<style scoped>
p.column {
  margin-bottom: 5px;
}
p.read,
p.write {
  margin-bottom: 20px;
}
.align-left {
  text-align: left;
}
</style>
