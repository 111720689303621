import { render, staticRenderFns } from "./Inventories.vue?vue&type=template&id=70e0decc&scoped=true&"
import script from "./Inventories.vue?vue&type=script&lang=js&"
export * from "./Inventories.vue?vue&type=script&lang=js&"
import style0 from "./Inventories.vue?vue&type=style&index=0&id=70e0decc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e0decc",
  null
  
)

export default component.exports